@import '~antd/dist/antd.css';
@import '~sanitize.css';
@import '~sanitize.css/forms.css';
@import '~sanitize.css/typography.css';

.ant-layout {
	background-color: #fff;
}

.ant-page-header-heading-extra {
	margin: 0;
}

.ant-badge-status-dot {
	width: 10px;
	height: 10px;
}

.ant-modal-title {
	text-align: center;
	font-weight: 700;
	font-size: 18px;
}

.ant-form-item.in-modal > div {
	align-items: center;
}

.ant-upload.ant-upload-select.ant-upload-select-picture-card {
	width: 121px;
	height: 121px;
	margin: 0;
}

.ant-upload-list-picture-card-container {
	width: 121px;
	height: 121px;
	margin: 0;
}

.ant-tag-checkable-checked {
	background-color: #cdcdee;
}

/* 프로필 페이지 */
.select_in_robotProfile .ant-select-selector {
	height: 50px !important;
}

.select_in_robotProfile.site .ant-select-selection-item {
	align-self: center;
}

.select_in_robotProfile.model .ant-form-item-control-input-content {
	height: 50px;
}

.upload_in_robotProfile .ant-form-item {
	margin-bottom: 0;
}

/* 사이트 페이지 */
.input_in_site .ant-form-item {
	margin-bottom: 0;
}

/* 로봇 페이지 */
.select_in_robotList .ant-form-item-control-input-content {
	height: 50px;
}

.select_in_robotList .ant-select-selector {
	height: 50px !important;
}

/* 회원 페이지 */
.select_in_userPage .ant-select-selector {
	height: 50px !important;
}

.text_in_userPage.actvie-baseline .ant-row.ant-form-item-row {
	align-items: baseline;
}

.text_in_userPage label {
	color: rgba(0, 0, 0, 0.45);
	font-weight: 600;
}

.checkbox_in_userPage .ant-form-item-control-input-content {
	overflow-y: auto;
	height: 200px;
}
